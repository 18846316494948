import {defineStore} from 'pinia'
import {useFormProcessStore} from './formProcess'
import {ref} from 'vue'

export const useFormConfigStore = defineStore('formConfig', () => {
    const formIdentifier = ref(null)
    const formFields = ref([])
    const formRulesInitial = ref({})
    const formRules = ref({})
    const formFieldsRequired = ref({})
    const formFieldsWithWildcardInRule = ref([])
    const formHelpTexts = ref({})
    const formFieldsWithWildcardInHelpText = ref([])

    function storeFormConfigData() {
        formIdentifier.value = useFormProcessStore().formProcessData.form.identifier
        formFields.value = useFormProcessStore().formProcessData.form.formFields
        formRulesInitial.value = useFormProcessStore().formProcessData.rules

        getFormRules()
        getRequiredFields()
        getHelpTexts()
    }

    function getFormRules() {
        Object.keys(formRulesInitial.value).forEach((fieldIdentifier) => {
            const rules = handleRules(fieldIdentifier)

            formRules.value[fieldIdentifier] = rules.join('|')
        })
    }

    function getRequiredFields() {
        Object.keys(formRulesInitial.value).forEach((fieldIdentifier) => {
            formFieldsRequired.value[fieldIdentifier] = formRulesInitial.value[fieldIdentifier].split('|').includes('required')
        })
    }

    function getHelpTexts() {
        formFields.value.forEach(field => {
            formHelpTexts.value[field.fieldIdentifier] = handleHelpTexts(field.helpText ?? null)
        })
    }

    function updateFormRules() {
        getFormRules()
    }

    function updateHelpTexts() {
        getHelpTexts()
    }

    function handleRules(fieldIdentifier) {
        let rules = formRulesInitial.value[fieldIdentifier].split('|')

        const isNumberField = formRulesInitial.value[fieldIdentifier].includes('numeric') ||
            formRulesInitial.value[fieldIdentifier].includes('integer') ||
            formRulesInitial.value[fieldIdentifier].includes('decimal')

        rules.forEach((rule, index) => {
            rule = replaceFieldIdentifiersInRule(rule, isNumberField)

            rule = calculateRule(rule)

            rules[index] = rule
        })

        rules = handleDuplicatedRules(rules)

        return rules
    }

    function replaceFieldIdentifiersInRule(rule, isNumberField) {
        const formProcessData = useFormProcessStore().formProcessData

        while(rule && rule.includes('{')) {
            const replaceFieldIdentifier = rule.substring(rule.indexOf('{') + 1, rule.indexOf('}'))

            if (!formFieldsWithWildcardInRule.value.includes(replaceFieldIdentifier))
                formFieldsWithWildcardInRule.value.push(replaceFieldIdentifier)

            if (formProcessData.data[replaceFieldIdentifier] !== null) {
                let replaceValue = formProcessData.data[replaceFieldIdentifier] || 0

                if (isNumberField) replaceValue = replaceValue.toString().replace(',', '.')

                rule = rule.replace('{' + replaceFieldIdentifier + '}', replaceValue)
            } else {
                rule = null
            }
        }

        return rule
    }

    function calculateRule(rule) {
        let calc = null
        let colonIndex = null

        // Check if the rule contains arithmetic operations and get the rules values
        if (rule.includes('+') || rule.includes('-') || rule.includes('*') || rule.includes('/')) {
            colonIndex = rule.indexOf(':')
            if (colonIndex !== -1) {
                calc = rule.substring(colonIndex + 1).trim();
            }
        }

        if (calc) {
            // Split the calc part into individual components, if necessary
            const parts = calc.split(',');

            for (let i = 0; i < parts.length; i++) {
                let part = parts[i].trim();

                // Check if this part contains an arithmetic expression
                if (part.match(/[+\-*/]/)) {
                    // Replace commas with dots for decimal numbers
                    const sanitizedPart = part.replace(',', '.');

                    try {
                        const result = eval(sanitizedPart);
                        parts[i] = Number(result);
                    } catch (e) {
                        console.error("Error evaluating calc:", e);
                    }
                }
            }
            // Rejoin the parts into the final calculated rule
            rule = rule.substring(0, colonIndex + 1) + parts.join(',');
        }

        return rule
    }

    function handleDuplicatedRules(rules) {
        const rulesKeyedByRuleName = {}
        rules.forEach(rule => {
            const ruleName = (rule.indexOf(':') >= 0) ? rule.substring(0, rule.indexOf(':')) : rule
            if (!(ruleName in rulesKeyedByRuleName)) rulesKeyedByRuleName[ruleName] = []
            rulesKeyedByRuleName[ruleName].push(rule.replace(ruleName + ':', ''))
        })

        Object.keys(rulesKeyedByRuleName).forEach(ruleName => {
            if (rulesKeyedByRuleName[ruleName].length > 1) {
                let value = null

                switch (ruleName) {
                    case 'max_value':
                    case 'max':
                        rulesKeyedByRuleName[ruleName].forEach((rule, index) => {
                            rulesKeyedByRuleName[ruleName][index] = parseFloat(rule)
                        })

                        value = Math.min(...rulesKeyedByRuleName[ruleName])
                        break
                    case 'min_value':
                    case 'min':
                        rulesKeyedByRuleName[ruleName].forEach((rule, index) => {
                            rulesKeyedByRuleName[ruleName][index] = parseFloat(rule)
                        })

                        value = Math.max(...rulesKeyedByRuleName[ruleName])
                        break
                    default:
                        value = rulesKeyedByRuleName[ruleName][0]
                }

                rulesKeyedByRuleName[ruleName] = [value]
            }
        })

        rules = []

        Object.keys(rulesKeyedByRuleName).forEach(ruleName => {
            if (ruleName === rulesKeyedByRuleName[ruleName][0]) {
                rules.push(ruleName)
            } else {
                rules.push(ruleName + ':' + rulesKeyedByRuleName[ruleName][0])
            }
        })

        return rules
    }

    function handleHelpTexts(helpText) {
        const formProcessData = useFormProcessStore().formProcessData

        while(helpText && helpText.includes('{')) {
            console.log("handleHelpTexts 1", helpText)
            const replaceFieldIdentifier = helpText.substring(helpText.indexOf('{') + 1, helpText.indexOf('}'))

            if (!formFieldsWithWildcardInHelpText.value.includes(replaceFieldIdentifier))
                formFieldsWithWildcardInHelpText.value.push(replaceFieldIdentifier)

            if (formProcessData.data[replaceFieldIdentifier] !== null) {
                let replaceValue = formProcessData.data[replaceFieldIdentifier]

                helpText = helpText.replace('{' + replaceFieldIdentifier + '}', replaceValue)
            } else {
                helpText = null
            }
        }

        return helpText
    }

    function resetData() {
        formIdentifier.value = null
        formFields.value = []
        formRulesInitial.value = {}
        formRules.value = {}
        formFieldsRequired.value = {}
        formFieldsWithWildcardInRule.value = []
        formHelpTexts.value = {}
        formFieldsWithWildcardInHelpText.value = []
    }

    return {
        formIdentifier,
        formFields,
        formRulesInitial,
        formRules,
        formFieldsRequired,
        formFieldsWithWildcardInRule,
        formHelpTexts,
        formFieldsWithWildcardInHelpText,
        storeFormConfigData,
        updateFormRules,
        updateHelpTexts,
        resetData
    }
})
